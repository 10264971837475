import React, { useContext } from 'react';

import { ReactComponent as KeyIcon } from 'i/icons/key.svg';
import { ReactComponent as ConfigCodeIcon } from 'i/icons/config_code.svg';
import { ReactComponent as DocumentIcon } from 'i/icons/document.svg';
import { ReactComponent as OrdersIcon } from 'i/icons/orders.svg';
import { ReactComponent as PlusIcon } from 'i/icons/plus.svg';
import { ReactComponent as UserIcon } from 'i/icons/user.svg';
import { ReactComponent as PensilIcon } from 'i/icons/pensil.svg';
import { ReactComponent as DubleArrowIcon } from 'i/icons/duble_arrow.svg';
import { ReactComponent as QuestionIcon } from 'i/icons/question.svg';
import { ReactComponent as WarningIcon } from 'i/icons/warning.svg';
import { ReactComponent as EqualsIcon } from 'i/icons/equals.svg';
import { GlassStructureContext } from 'context/configuration';
import GlassConfigDropdown from 'components/GlassConfig/GlassConfigDropdown';
import classNames from 'classnames';
import DatasheetBox from 'components/GlassProduct/DatasheetBox';
import i18n from 'utils/i18n';

const BASE_ICON_CLASS = 'icon size_mod';

const BlockHeading = ({
	title = 'Title',
	optionalTitle,
	iconName,
	showGlassDropdownMenu = false,
	parent,
	attrKey,
	glassData,
	blockIndex,
	offsetMod,
	showPrice, // only needed with showGlassDropdownMenu = true
}) => {
	const { uiHandler } = useContext(GlassStructureContext);
	const blockHeadingClasses = classNames('block_heading', {
		'block_heading--offset_mod': offsetMod,
	});

	const datasheetUrl = glassData
		&& uiHandler.glassDB.getDatasheet(
			glassData.flatGlassStructure,
			i18n.language,
		);
	const renderHeadingIcon = () => {
		if (iconName === 'config_code') {
			return <ConfigCodeIcon className={BASE_ICON_CLASS} />;
		}

		if (iconName === 'document') {
			return <DocumentIcon className={BASE_ICON_CLASS} />;
		}

		if (iconName === 'plus') {
			return <PlusIcon className={BASE_ICON_CLASS} />;
		}

		if (iconName === 'orders') {
			return <OrdersIcon className={BASE_ICON_CLASS} />;
		}

		if (iconName === 'user') {
			return <UserIcon className={BASE_ICON_CLASS} />;
		}

		if (iconName === 'pensil') {
			return <PensilIcon className={BASE_ICON_CLASS} />;
		}

		if (iconName === 'duble_arrow') {
			return <DubleArrowIcon className={BASE_ICON_CLASS} />;
		}

		if (iconName === 'question') {
			return <QuestionIcon className={BASE_ICON_CLASS} />;
		}

		if (iconName === 'warning') {
			return <WarningIcon className={BASE_ICON_CLASS} />;
		}

		if (iconName === 'equals') {
			return <EqualsIcon className={BASE_ICON_CLASS} />;
		}

		return <KeyIcon className="icon icon-key size_mod" />;
	};

	return (
		<div className={blockHeadingClasses}>
			<div className="block_heading_start">
				<div className="block_heading__icon">
					{blockIndex ? (
						<div className="block_heading__index">{`${blockIndex}.`}</div>
					) : (
						renderHeadingIcon()
					)}
				</div>
				<h2 className="block_heading__title">{title}</h2>
				{optionalTitle && (
					<div className="block_heading__subtitle">{optionalTitle}</div>
				)}
				{showGlassDropdownMenu && (
					<GlassConfigDropdown
						parent={parent}
						attrKey={attrKey}
						glassData={glassData}
						showPrice={showPrice}
					/>
				)}
			</div>
			{datasheetUrl && (
				<DatasheetBox datasheetUrl={datasheetUrl} />
			)}
		</div>
	);
};

export default BlockHeading;
