import React, {
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BackButton from 'components/Buttons/BackButton';
import { useGlassSize } from 'hooks';
import Freezer from 'freezer-js';
import { ConfigurationCode } from 'components/ConfigurationCode';
import { YourReference } from 'components/YourReference';
import { GlassConfig } from 'components/GlassConfig';
import { UploadedDocuments } from 'components/UploadedDocuments';
import { SaveAsSection } from 'components/SaveAsSection';
import { AddNewType } from 'components/AddNewType';
import { TotalInfo } from 'components/TotalInfo';
import { ConfigurationDataContext, GlassStructureContext } from 'context/configuration';
import { cloneObjectWithoutReference, getCurrentDate } from 'utils';
import GlassConfigUtil from 'components/GlassConfig/GlassConfigUtil';
import i18n from 'utils/i18n';

const CartPage = () => {
	const navigate = useNavigate();
	const {
		configuration,
		totalGlassSizes,
		totalGlassSizesSum,
		setConfiguration,
		loadConfigurationToFirebase,
	} = useContext(ConfigurationDataContext);
	const { showPrice, uiHandlerRef } = useContext(GlassStructureContext);
	const { t } = useTranslation('cartPage');
	const [configurationJsonData, setConfigurationJsonData] = useState(null);
	const [isListenerInitialized, setListenerInitialized] = useState(false);
	const currentDateRef = useRef(getCurrentDate());
	const frozenRef = useRef();

	useGlassSize(configurationJsonData);

	useEffect(() => {
		if (configuration && !frozenRef.current) {
			frozenRef.current = new Freezer({ json: configuration });

			setConfigurationJsonData(frozenRef.current.get().json);
		} else if (!configuration) {
			const timer = setTimeout(() => {
				navigate('/');
			}, 5000);

			return () => { clearTimeout(timer); };
		}
		return () => { };
	}, [navigate, configuration]);

	useEffect(() => {
		if (configurationJsonData && !isListenerInitialized) {
			const listener = configurationJsonData.getListener();

			listener.on('update', currentState => {
				setConfigurationJsonData(currentState);

				const clonedConfiguration = cloneObjectWithoutReference(currentState);

				clonedConfiguration.amountGlassTypes = clonedConfiguration.glassTypes.length;
				clonedConfiguration.lastEdited = currentDateRef.current;
				clonedConfiguration.lastEditedDateInMilliseconds = Date.now();

				setConfiguration(clonedConfiguration);
				loadConfigurationToFirebase(clonedConfiguration, false);
			});

			setListenerInitialized(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [configurationJsonData]);

	useEffect(() => {
		const langLowerCase = i18n.language.toLowerCase();
		const lang = (langLowerCase === 'en' ? 'en_US' : langLowerCase);
		if (!configurationJsonData?.glassTypes.length || configurationJsonData.glassTypes.every((g) => g.lang === lang)) return;

		let newConfig = cloneObjectWithoutReference(configurationJsonData);
		newConfig.glassTypes.map((glassData, idx) => {
			Object.assign(glassData, GlassConfigUtil.translate(glassData, lang, uiHandlerRef));
			frozenRef.current.get().json.glassTypes[idx].set(glassData);
			return glassData;
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [i18n.language, configurationJsonData]);

	if (!configuration) {
		return (
			<section className="section">
				<div className="section__in">
					<div className="section__block">
						<h1 className="section__title section__center">
							{t('cartIsEmpty')}
						</h1>
						<div className="section__center">
							<BackButton
								buttonText={t('orderConfirmationPage:backToStart')}
								navigateToUrl="/"
							/>
						</div>
					</div>
				</div>
			</section>
		);
	}

	return (
		<section className="section">
			<div className="section__in">
				{configurationJsonData ? (
					<>
						<div className="section__block">
							<ConfigurationCode
								code={configuration.code}
							/>
						</div>
						<div className="section__block">
							<YourReference
								parent={configurationJsonData}
								values={{
									reference: configurationJsonData.reference,
									cantonalInsurance: configurationJsonData.cantonalInsurance,
									buildingReference: configurationJsonData.buildingReference,
								}}
							/>
						</div>
						{configurationJsonData.glassTypes && configurationJsonData.glassTypes.length ? (
							configurationJsonData.glassTypes.map((glassData, index) => {
								return (
									<div className="section__block" key={glassData.id || index}>
										<GlassConfig
											parent={configurationJsonData.glassTypes}
											totalValues={totalGlassSizes[index]}
											glassData={glassData}
											attrKey={index}
											glassIndex={index + 1}
											showHeadingDropdownMenu
											showPrice={showPrice}
										/>
									</div>
								);
							})
						) : null}
						<div className="section__block">
							<AddNewType />
						</div>
						<div className="section__block">
							<TotalInfo {...totalGlassSizesSum} showPrice={showPrice} />
						</div>
						<div className="section__block">
							<UploadedDocuments uploadedDocuments={configurationJsonData.uploadedDocuments} />
							<SaveAsSection isCartPage />
						</div>
					</>
				) : null}
			</div>
		</section>
	);
};

export default CartPage;
