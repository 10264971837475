import { useContext, useEffect } from 'react';
import { getGlassSizes } from 'domain/glass';
import { ConfigurationDataContext } from 'context/configuration';
import { AuthContext } from 'context/auth';
import { AppDataContext } from 'context/appData';

const useGlassSize = (configurationJsonData) => {
	const {
		setTotalGlassSizes,
		setTotalGlassSizesSum,
		setIsSizesFilled,
		setErrorFields,
	} = useContext(ConfigurationDataContext);
	const { currentUserAdditionalData } = useContext(AuthContext);
	const { configuratorData } = useContext(AppDataContext);

	useEffect(() => {
		if (!configurationJsonData?.glassTypes?.length) return;
		const {
			values, valuesSum, isSizesValid, emptyOrZeroFields,
		} = getGlassSizes(configurationJsonData.glassTypes, currentUserAdditionalData?.company?.showPrices || false, configuratorData);

		setTotalGlassSizes(values);
		setIsSizesFilled(isSizesValid);
		setTotalGlassSizesSum(valuesSum);
		setErrorFields(emptyOrZeroFields);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [configurationJsonData, currentUserAdditionalData, configuratorData]);
};

export default useGlassSize;
